@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:200,300,400,400i,500,500i,600,600i,700,700i);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}
.ht-100v {
    height: 100vh;
}
.login-bg {
        background: url(/static/media/bg.20f3e0ae.jpg);
    background-position: center;
    background-size: cover;
    position: relative;
}
.login-bg-overlay {
    background: rgba(0,0,0,0.4);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: block;
}
.d-flex {
    display: flex!important;
}
.align-self-center {
    align-self: center!important;
}
.tx-20 {
    font-size: 20px;
}
.tx-gray-100 {
    color: #f8f9fa;
}

.tx-semibold {
    font-weight: 600;
}
.pd-t-50 {
    padding-top: 50px;
}
.tx-gray-200 {
    color: #e9ecef;
}
@media (min-width: 768px) {
.pd-md-x-100 {
    padding-left: 200px;
    padding-right: 200px;
}
}
.pd-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}
.text-center {
    text-align: center!important;
}
.bg-light {
    background-color: #f8f9fa !important;
}
.align-items-center {
    align-items: center!important;
}

.justify-content-center {
    justify-content: center!important;
}
.tx-left {
    text-align: left;
}
.tx-dark {
    color: #001737;
}

.mg-b-5 {
    margin-bottom: 5px;
}

.bg-light h3, h3 {
    font-size: 1.75rem;
        margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.25;
    color: #021222;
}
.btn-block {
  width:100%;
}
.Counter_row__2NssN {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Counter_row__2NssN:not(:last-child) {
  margin-bottom: 16px;
}

.Counter_value__15QX5 {
  font-size: 78px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 2px;
  font-family: 'Courier New', Courier, monospace;
}

.Counter_button__xnuwF {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  font-size: 32px;
  padding-left: 12px;
  padding-right: 12px;
  outline: none;
  border: 2px solid transparent;
  color: rgb(112, 76, 182);
  padding-bottom: 4px;
  cursor: pointer;
  background-color: rgba(112, 76, 182, 0.1);
  border-radius: 2px;
  transition: all 0.15s;
}

.Counter_textbox__tmQIR {
  font-size: 32px;
  padding: 2px;
  width: 64px;
  text-align: center;
  margin-right: 8px;
}

.Counter_button__xnuwF:hover, .Counter_button__xnuwF:focus {
  border: 2px solid rgba(112, 76, 182, 0.4);
}

.Counter_button__xnuwF:active {
  background-color: rgba(112, 76, 182, 0.2);
}

.Counter_asyncButton__3S-Gd {
  position: relative;
  margin-left: 8px;
}

.Counter_asyncButton__3S-Gd:after {
  content: "";
  background-color: rgba(112, 76, 182, 0.15);
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  transition: width 1s linear, opacity 0.5s ease 1s;
}

.Counter_asyncButton__3S-Gd:active:after {
  width: 0%;
  opacity: 1;
  transition: 0s
}

.viewproject {
text-align: center;
margin-bottom: 20px;
}
.viewproject .ant-card-body {
    box-shadow: 0 3px 20px rgba(0,0,0,.04);
}
.viewproject .ant-card-bordered {
    border: none;
}
.viewproject .ant-checkbox-wrapper {
    position: absolute;
    left: 11px;
    top: 11px;
}
.viewproject .anticon-delete {
    position: absolute;
    right: 11px;
    top: 11px;
    
}
.viewproject .anticon-delete svg {
    font-size: 14px;
}
.viewproject .ant-typography {
    font-size: 16px;
    margin:10px 0 0 0;
}
.viewproject em {
    color:#d8d7d7
}
.viewproject svg {
font-size: 28px;;
}
body {
  font-family: "IBM Plex Sans", sans-serif !important;
  color: #232a31 !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-float infinite 3s ease-in-out;
            animation: App-logo-float infinite 3s ease-in-out;
  }
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@-webkit-keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.mt-30 {
  margin-top: 30px;
}
.ant-table {
  color: #212529;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > td {
  border-right: 1px solid #eceff3;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #eceff3;
}

.ant-input-number {
  width: 100% !important;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

th {
  background-color: #fafafa;
}

.ant-table.ant-table-small {
  font-size: 12px !important;
}

.error {
  color: #ff4d4f;
}
.ant-btn-primary {
  background: #f37523;
    border-color: #f37523;
}
.ant-table-thead > tr > th, .ant-table-small .ant-table-thead > tr > th {
      background: #f37523;
          color:#fff;
          border-bottom: 1px solid #f49e66;
            border: 1px solid #f49e66 !important;
}
.ant-form-item {
      margin-bottom: 14px;
}
.ant-input, .ant-input-number-input {
      padding: 4px 11px;
       height: 40px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 4px 11px;
  height: 40px;
}
.text-right {
  text-align: right;
}
.ant-table-content p {
  margin-bottom: 0;
}
